<template>
    <div class="newmanager-info">
        <div class="img-wrap">
            <img :src="itemBg" class="itembg-img">
            <div>标签管理</div>
        </div>
        <div class="content">
            <div class="title">基本信息</div>
            <van-field label="发展标签" readonly v-model="siteInfo.stationTag" input-align="right"/>
            <van-field label="站点类型" readonly v-if="siteTypeTxt!=''" v-model="siteTypeTxt" input-align="right"/>
            <div class="title">标签信息</div>
            <van-cell title="支行支持标签" is-link arrow-direction="down"
                      :value="bankCooperationTxt" placeholder="请选择"
                      @click="showBankCooperation=true"/>
            <van-popup v-model="showBankCooperation" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="bankCooperationType"
                        @cancel="showBankCooperation = false"
                        @confirm="selectBankCooperationType"
                />
            </van-popup>
            <van-cell title="站长经营标签" is-link arrow-direction="down"
                      :value="masterCooperationTxt" placeholder="请选择"
                      @click="showMasterCooperation=true"/>
            <van-popup v-model="showMasterCooperation" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="masterCooperationType"
                        @cancel="showMasterCooperation = false"
                        @confirm="selectMasterCooperationType"
                />
            </van-popup>
            <van-cell title="建设类型标签" is-link arrow-direction="down"
                      :value="constructionTypeTxt" placeholder="请选择"
                      @click="showConstructionType=true"/>
            <van-popup v-model="showConstructionType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="constructionType"
                        @cancel="showConstructionType = false"
                        @confirm="selectConstructionType"
                />
            </van-popup>
            <van-field v-model="addPara.constructionTypeDesc"
                       label="混业类型"
                       placeholder="超市、快递、保险等非公司合作业务"
                       v-if="addPara.constructionType=='0'"
            ></van-field>
            <van-cell title="场所经营标签" is-link arrow-direction="down"
                      :value="businessPlaceTxt" placeholder="请选择"
                      @click="showBusinessPlaceType=true"/>
            <van-popup v-model="showBusinessPlaceType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="businessPlaceType"
                        @cancel="showBusinessPlaceType = false"
                        @confirm="selectBusinessPlaceType"
                />
            </van-popup>
            <van-field v-model="addPara.actualBusinessPlace"
                       label="实际经营地"
                       placeholder="实际经营地址"
                       v-if="addPara.businessPlaceType=='1'"
            ></van-field>
            <van-cell title="业绩来源标签" is-link arrow-direction="down"
                      :value="performanceSourceTxt" placeholder="请选择"
                      @click="showPerformanceSource=true"/>
            <van-popup v-model="showPerformanceSource" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="performanceSourceType"
                        @cancel="showPerformanceSource = false"
                        @confirm="selectPerformanceSourceType"
                />
            </van-popup>
            <van-cell title="职业经历标签" is-link arrow-direction="down"
                      :value="workExperiencesTxt" placeholder="请选择"
                      @click="showWorkExperiencesType=true"/>
            <van-popup v-model="showWorkExperiencesType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="workExperiencesType"
                        @cancel="showWorkExperiencesType = false"
                        @confirm="selectWorkExperiencesType"
                />
            </van-popup>
            <van-field v-model="addPara.workExperiencesDesc"
                       label="职业经历"
                       placeholder="站长在**机构+干什么"
                       v-if="addPara.workExperiences!='0'&&addPara.workExperiences!=''"
            ></van-field>
            <van-cell title="干系人标签" is-link arrow-direction="down"
                      :value="actualOperatorTxt" placeholder="请选择"
                      @click="showActualOperatorType=true"/>
            <van-popup v-model="showActualOperatorType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="actualOperatorType"
                        @cancel="showActualOperatorType = false"
                        @confirm="selectActualOperatorType"
                />
            </van-popup>
            <van-field v-model="addPara.actualOperatorRelation"
                       label="关系"
                       placeholder="站长与干系人关系"
                       v-if="addPara.actualOperator=='3'||addPara.actualOperator=='2'"
            ></van-field>
            <van-field v-model="addPara.actualOperatorName"
                       label="姓名"
                       placeholder="干系人姓名"
                       v-if="addPara.actualOperator=='3'||addPara.actualOperator=='2'"
            ></van-field>
            <van-field v-model="addPara.actualOperatorPhone"
                       label="手机号"
                       :maxlength="11"
                       type="tel"
                       :formatter="phoneFormatter"
                       placeholder="干系人手机"
                       v-if="addPara.actualOperator=='3'||addPara.actualOperator=='2'"
            ></van-field>
            <van-cell title="干系人业务标签" is-link arrow-direction="down"
                      :value="fromOtherBanksTxt" placeholder="请选择"
                      @click="showFromOtherBanksType=true"/>
            <van-popup v-model="showFromOtherBanksType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="fromOtherBanksType"
                        @cancel="showFromOtherBanksType = false"
                        @confirm="selectFromOtherBanksType"
                />
            </van-popup>
            <van-cell title="本人or干系人" is-link arrow-direction="down"
                      :value="operatorTxt" placeholder="请选择"
                      v-if="addPara.fromOtherBanks!='0'&&addPara.fromOtherBanks!=''"
                      @click="showOperatorType=true"/>
            <van-popup v-model="showOperatorType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="operatorType"
                        @cancel="showOperatorType = false"
                        @confirm="selectOperatorType"
                />
            </van-popup>
            <van-field v-model="addPara.operatorRelation"
                       label="关系"
                       placeholder="站长与干系人关系"
                       v-if="addPara.operator=='2'">
                >
            </van-field>
            <van-field v-model="addPara.operatorName"
                       label="姓名"
                       placeholder="干系人姓名"
                       v-if="addPara.operator=='2'"
            ></van-field>
            <van-field v-model="addPara.operatorPhone"
                       label="手机号"
                       :maxlength="11"
                       type="tel"
                       :formatter="phoneFormatter"
                       placeholder="干系人手机"
                       v-if="addPara.operator=='2'"
            ></van-field>
            <van-field v-model="addPara.industry"
                       label="行业及机构"
                       placeholder="具体行业及机构"
                       v-if="addPara.operator!=''"
            ></van-field>
            <van-field v-model="addPara.businessDescription"
                       label="业务描述"
                       placeholder="具体业务描述"
                       v-if="addPara.operator!=''"
            ></van-field>
            <van-field v-model="addPara.serviceYears"
                       label="服务年限"
                       placeholder="站长服务年限"
                       v-if="addPara.operator=='1'"
            ></van-field>
            <van-field v-model="addPara.reasonForDoing"
                       label="干或不干原因"
                       placeholder="站长干或不干的原因描述"
                       v-if="addPara.operator=='1'"
            ></van-field>
            <div class="title">风险自评</div>
            <van-cell title="自评标签" is-link arrow-direction="down"
                      :value="riskLevelTxt" placeholder="请选择"
                      @click="showriskLevelType=true"/>
            <van-popup v-model="showriskLevelType" round position="bottom">
                <van-picker
                        show-toolbar
                        :columns="riskLevelType"
                        @cancel="showriskLevelType = false"
                        @confirm="selectRiskLevelType"
                />
            </van-popup>
            <van-field v-model="addPara.notes"
                       label="备注"
                       placeholder="自评考虑原因"
                       type="textarea"
                       rows="2"
                       autosize
            ></van-field>

        </div>
        <div class="nextBtn flex">
            <van-button class="publicBtnActive left-btn"
                        @click="back">返回
            </van-button>
            <van-button class="publicBtnActive"  v-if="isSame === '1'"
                        @click="updateRiskInfo">保存
            </van-button>
        </div>


    </div>
</template>

<script>
    import common from '@/utils/common'
    import {updStationRiskTag, getSiteByStationNo} from '@/utils/api'

    export default {
        data() {
            return {
                stationNo: this.$route.query.stationNo,
                myUniqueNo:common.getItem('uniqueNo'),
                marketerNo:'',
                isSame:'',
                siteInfo: {},
                riskTagObj: {},
                itemBg: require('@/assets/img/site/itemBg.png'),
                bankCooperationTxt: '',
                siteTypeTxt: '',
                masterCooperationTxt: '',
                constructionTypeTxt: '',
                businessPlaceTxt: '',
                performanceSourceTxt: '',
                workExperiencesTxt: '',
                actualOperatorTxt: '',
                fromOtherBanksTxt: '',
                operatorTxt: '',
                riskLevelTxt: '',
                showBankCooperation: false,
                showMasterCooperation: false,
                showConstructionType: false,
                showBusinessPlaceType: false,
                showPerformanceSource: false,
                showWorkExperiencesType: false,
                showActualOperatorType: false,
                showFromOtherBanksType: false,
                showOperatorType: false,
                showriskLevelType: false,
                siteTypeList: {
                    '': '',
                    '0': '轻型站点',
                    '1': '标准站点'
                },
                bankCooperationType: [
                    {text: '请选择', value: ''},
                    {text: '高', value: '3'},
                    {text: '正常', value: '2'},
                    {text: '一般', value: '1'},
                    {text: '弱', value: '0'}
                ],
                bankCooperationType2: {
                    '': '请选择',
                    '3': '高',
                    '2': '正常',
                    '1': '一般',
                    '0': '弱'
                },
                bankCooperationType3: {
                    '3': '会议、活动、开户、政策配套、人员协同、业绩推动等参与紧密支持高',
                    '2': '常规协调后正常参与',
                    '1': '多次或重点协调后，偶有推动',
                    '0': '很难协调，基本不支持推动'
                },
                masterCooperationType: [
                    {text: '请选择', value: ''},
                    {text: '好', value: '3'},
                    {text: '正常', value: '2'},
                    {text: '一般', value: '1'},
                    {text: '弱', value: '0'}
                ],
                masterCooperationType2: {
                    '': '请选择',
                    '3': '好',
                    '2': '正常',
                    '1': '一般',
                    '0': '弱'
                },
                masterCooperationType3: {
                    '3': '开门、宣传活动、会议、交易、业务技巧、投入力等好',
                    '2': '常规协调后正常经营',
                    '1': '多次或重点协调后，偶有推动',
                    '0': '很难协调，基本不经营'
                },
                constructionType: [
                    {text: '请选择', value: ''},
                    {text: '村委', value: '3'},
                    {text: '企业', value: '2'},
                    {text: '独立', value: '1'},
                    {text: '混业', value: '0'}
                ],
                constructionType2: {
                    '': '请选择',
                    '3': '村委',
                    '2': '企业',
                    '1': '独立',
                    '0': '混业'
                },
                constructionType3: {
                    '3': '建在村委、社区居委会等',
                    '2': '建在企业',
                    '1': '自有或租房经营独立业务',
                    '0': '有超市、快递、保险等其他非公司安排业务混业经营'
                },
                businessPlaceType: [
                    {text: '请选择', value: ''},
                    {text: '经营地与站点一致', value: '2'},
                    {text: '经营地与站点不一致', value: '1'}
                ],
                businessPlaceType2: {
                    '': '请选择',
                    '2': '经营地与站点一致',
                    '1': '经营地与站点不一致'
                },
                businessPlaceType3: {
                    '2': '实际经营地与系统站点一致',
                    '1': '实际经营地与系统站点不一致'
                },
                performanceSourceType: [
                    {text: '请选择', value: ''},
                    {text: '拆迁征地', value: '3'},
                    {text: '企业资金', value: '2'},
                    {text: '大客户集中', value: '1'},
                    {text: '基础客户', value: '0'}
                ],
                performanceSourceType2: {
                    '': '请选择',
                    '3': '拆迁征地',
                    '2': '企业资金',
                    '1': '大客户集中',
                    '0': '基础客户'
                },
                performanceSourceType3: {
                    '3': '通过拆迁或征地等占比较大业绩',
                    '2': '通过代发薪、企业主等占比较大业绩',
                    '1': '业绩中为1个或几个大客户为主构成的',
                    '0': '普通基础客户'
                },
                workExperiencesType: [
                    {text: '请选择', value: ''},
                    {text: '银行退休', value: '1'},
                    {text: '其他经济组织在干', value: '2'},
                    {text: '其他经济组织经验', value: '3'},
                    {text: '政府在干或经验', value: '4'},
                    {text: '其他银行在干或经验', value: '5'},
                    {text: '无上述经历', value: '0'}
                ],
                workExperiencesType2: {
                    '': '请选择',
                    '1': '银行退休',
                    '2': '其他经济组织在干',
                    '3': '其他经济组织经验',
                    '4': '政府在干或经验',
                    '5': '其他银行在干或经验',
                    '0': '无上述经历',
                },
                workExperiencesType3: {
                    '1': '合作行或他行已退休',
                    '2': '保险、证券或其他经济组织等职业在干',
                    '3': '保险、证券或其他经济组织等有经历已不干',
                    '4': '村委或其他政府机构在干或有经历已不干',
                    '5': '其他银行在干或有经验已不干'
                },
                actualOperatorType: [
                    {text: '请选择', value: ''},
                    {text: '本行家属亲属', value: '3'},
                    {text: '受托经营', value: '2'},
                    {text: '家属或无干系人', value: '1'}
                ],
                actualOperatorType2: {
                    '': '请选择',
                    '3': '本行家属亲属',
                    '2': '受托经营',
                    '1': '家属或无干系人'
                },
                actualOperatorType3: {
                    '3': '站长是合作行的家属或亲属',
                    '2': '站长主要现场服务，业务由非家属之外亲属或他人为主开拓',
                    '1': '业务靠家属（父母、子女、夫妻）开拓或无干系人'
                },
                fromOtherBanksType: [
                    {text: '请选择', value: ''},
                    {text: '曾有他行', value: '4'},
                    {text: '现有他行', value: '3'},
                    {text: '曾有其他经济组织', value: '2'},
                    {text: '现有其他经济组织', value: '1'},
                    {text: '正常', value: '0'}
                ],
                fromOtherBanksType2: {
                    '': '请选择',
                    '1': '现有其他经济组织',
                    '2': '曾有其他经济组织',
                    '3': '现有他行',
                    '4': '曾有他行',
                    '0': '正常',
                },
                fromOtherBanksType3: {
                    '1': '本人或干系人现在有其他非银行机构的金融在经营',
                    '2': '本人或干系人有其他非银行机构的金融经历',
                    '3': '本人或干系人现在有银行助农业务相似经营',
                    '4': '本人或干系人之前有银行助农业务相似经历',
                    '0': '本人或干系人无金融经历',
                },
                operatorType: [
                    {text: '请选择', value: ''},
                    {text: '站长本人', value: '1'},
                    {text: '干系人', value: '2'}
                ],
                operatorType2: {
                    '': '请选择',
                    '2': '干系人',
                    '1': '站长本人'
                },
                riskLevelType: [
                    {text: '请选择', value: ''},
                    {text: '重点关注', value: '3'},
                    {text: '中度关注', value: '2'},
                    {text: '正常关注', value: '1'}
                ],
                riskLevelType2: {
                    '': '请选择',
                    '3': '重点关注',
                    '2': '中度关注',
                    '1': '正常关注'
                },
                riskLevelType3: {
                    '3': '风险隐患角度考虑，需要持续较强的关注度',
                    '2': '风险隐患角度考虑，应该比常规管理稍需增强关注',
                    '1': '常规管理，正常关注'
                },

                addPara: {
                    riskLevel: '',
                    actualBusinessPlace: '',
                    actualOperator: '',
                    actualOperatorRelation: '',
                    actualOperatorName: '',
                    actualOperatorId: '',
                    constructionType: '',
                    fromOtherBanks: '',
                    fromOtherBanksName: '',
                    performanceSource: '',
                    workExperiences: '',
                    businessPlaceType: '',
                    notes: '',
                    bankCooperation: '',
                    masterCooperation: '',
                    developmentTag: '',
                    constructionTypeDesc: '',
                    workExperiencesDesc: '',
                    operator: '',
                    operatorRelation: '',
                    operatorName: '',
                    operatorPhone: '',
                    industry: '',
                    businessDescription: '',
                    serviceYears: '',
                    reasonForDoing: '',
                    actualOperatorPhone: ''
                },
            }
        },
        mounted() {
            //this.myUniqueNo = common.getItem('uniqueNo');
            this.getsiteInfo()
        },
        methods: {
            getsiteInfo() {
                getSiteByStationNo({
                    stationNo: this.$route.query.stationNo
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            this.siteInfo = res.data.data
                            this.marketerNo=this.siteInfo.marketerNo;
                            this.isSame=this.siteInfo.isSame;
                            console.log("this.siteInfo:", this.siteInfo)
                            if (this.siteInfo.riskTagObj) {
                                let dataObj = JSON.parse(this.siteInfo.riskTagObj);
                                console.log("dataObj:", dataObj);
                                this.bankCooperationTxt = this.bankCooperationType2[dataObj.bankCooperation],
                                    this.siteTypeTxt = this.siteTypeList[dataObj.siteType],
                                    this.masterCooperationTxt = this.masterCooperationType2[dataObj.masterCooperation],
                                    this.constructionTypeTxt = this.constructionType2[dataObj.constructionType],
                                    this.businessPlaceTxt = this.businessPlaceType2[dataObj.businessPlaceType],
                                    this.performanceSourceTxt = this.performanceSourceType2[dataObj.performanceSource],
                                    this.workExperiencesTxt = this.workExperiencesType2[dataObj.workExperiences],
                                    this.actualOperatorTxt = this.actualOperatorType2[dataObj.actualOperator],
                                    this.fromOtherBanksTxt = this.fromOtherBanksType2[dataObj.fromOtherBanks],
                                    this.operatorTxt = this.operatorType2[dataObj.operator],
                                    this.riskLevelTxt = this.riskLevelType2[dataObj.riskLevel],
                                    this.addPara = {
                                        riskLevel: dataObj.riskLevel == null ? '' : dataObj.riskLevel,
                                        siteType: dataObj.siteType == null ? '' : dataObj.siteType,
                                        actualBusinessPlace: dataObj.actualBusinessPlace == null ? '' : dataObj.actualBusinessPlace,
                                        constructionType: dataObj.constructionType == null ? '' : dataObj.constructionType,
                                        actualOperator: dataObj.actualOperator == null ? '' : dataObj.actualOperator,
                                        actualOperatorRelation: dataObj.actualOperatorRelation == null ? '' : dataObj.actualOperatorRelation,
                                        actualOperatorName: dataObj.actualOperatorName == null ? '' : dataObj.actualOperatorName,
                                        actualOperatorId: dataObj.actualOperatorId == null ? '' : dataObj.actualOperatorId,
                                        performanceSource: dataObj.performanceSource == null ? '' : dataObj.performanceSource,
                                        fromOtherBanks: dataObj.fromOtherBanks == null ? '' : dataObj.fromOtherBanks,
                                        fromOtherBanksName: dataObj.fromOtherBanksName == null ? '' : dataObj.fromOtherBanksName,
                                        workExperiences: dataObj.workExperiences == null ? '' : dataObj.workExperiences,
                                        notes: dataObj.notes == null ? '' : dataObj.notes,
                                        bankCooperation: dataObj.bankCooperation == null ? '' : dataObj.bankCooperation,
                                        masterCooperation: dataObj.masterCooperation == null ? '' : dataObj.masterCooperation,
                                        developmentTag: dataObj.developmentTag == null ? '' : dataObj.developmentTag,
                                        constructionTypeDesc: dataObj.constructionTypeDesc == null ? '' : dataObj.constructionTypeDesc,
                                        workExperiencesDesc: dataObj.workExperiencesDesc == null ? '' : dataObj.workExperiencesDesc,
                                        operator: dataObj.operator == null ? '' : dataObj.operator,
                                        operatorRelation: dataObj.operatorRelation == null ? '' : dataObj.operatorRelation,
                                        operatorName: dataObj.operatorName == null ? '' : dataObj.operatorName,
                                        operatorPhone: dataObj.operatorPhone == null ? '' : dataObj.operatorPhone,
                                        industry: dataObj.industry == null ? '' : dataObj.industry,
                                        businessDescription: dataObj.businessDescription == null ? '' : dataObj.businessDescription,
                                        serviceYears: dataObj.serviceYears == null ? '' : dataObj.serviceYears,
                                        reasonForDoing: dataObj.reasonForDoing == null ? '' : dataObj.reasonForDoing,
                                        actualOperatorPhone: dataObj.actualOperatorPhone == null ? '' : dataObj.actualOperatorPhone,
                                        businessPlaceType: dataObj.businessPlaceType == null ? '' : dataObj.businessPlaceType
                                    }
                            }else{
                                this.addPara={
                                    riskLevel: '',
                                        actualOperator: '',
                                        actualOperatorRelation: '',
                                        actualOperatorName: '',
                                        actualOperatorId: '',
                                        constructionType: '',
                                        fromOtherBanks: '',
                                        fromOtherBanksName: '',
                                        performanceSource: '',
                                        workExperiences: '',
                                        notes: '',
                                        bankCooperation: '',
                                        masterCooperation: '',
                                        developmentTag: '',
                                        constructionTypeDesc: '',
                                        workExperiencesDesc: '',
                                        operator: '',
                                        operatorRelation: '',
                                        operatorName: '',
                                        operatorPhone: '',
                                        industry: '',
                                        businessDescription: '',
                                        serviceYears: '',
                                        reasonForDoing: '',
                                        actualBusinessPlace: '',
                                        businessPlaceType: '',
                                        actualOperatorPhone: '',
                                        siteType:''
                                },
                                    this.bankCooperationTxt = this.bankCooperationType2[''],
                                    this.masterCooperationTxt = this.masterCooperationType2[''],
                                    this.constructionTypeTxt = this.constructionType2[''],
                                    this.businessPlaceTxt = this.businessPlaceType2[''],
                                    this.performanceSourceTxt = this.performanceSourceType2[''],
                                    this.workExperiencesTxt = this.workExperiencesType2[''],
                                    this.actualOperatorTxt = this.actualOperatorType2[''],
                                    this.fromOtherBanksTxt = this.fromOtherBanksType2[''],
                                    this.operatorTxt = this.operatorType2[''],
                                    this.riskLevelTxt = this.riskLevelType2[''],
                                this.siteTypeTxt = this.siteTypeList['']
                            }
                        }
                    }
                })
            },
            phoneFormatter(val) {
                return val.replace(/[^\d]/g, "")
            },
            selectBankCooperationType(value) {
                this.bankCooperationTxt = value.text;
                this.addPara.bankCooperation = value.value;
                this.showBankCooperation = false;
                if(value.value!=''){
                    this.$toast(this.bankCooperationType2[value.value]+":"+this.bankCooperationType3[value.value])
                }
            },
            selectMasterCooperationType(value) {
                this.masterCooperationTxt = value.text;
                this.addPara.masterCooperation = value.value;
                this.showMasterCooperation = false;
                if(value.value!=''){
                    this.$toast(this.masterCooperationType2[value.value]+":"+this.masterCooperationType3[value.value])
                }
            },
            selectBusinessPlaceType(value){
                this.businessPlaceTxt = value.text;
                this.addPara.businessPlaceType = value.value;
                this.showBusinessPlaceType = false;
                if(value.value!=''){
                    this.$toast(this.businessPlaceType3[value.value])
                }
            },
            selectConstructionType(value) {
                this.constructionTypeTxt = value.text;
                this.addPara.constructionType = value.value;
                this.showConstructionType = false;
                if(value.value!=''){
                    this.$toast(this.constructionType2[value.value]+":"+this.constructionType3[value.value])
                }
            },
            selectPerformanceSourceType(value) {
                this.performanceSourceTxt = value.text;
                this.addPara.performanceSource = value.value;
                this.showPerformanceSource = false;
                if(value.value!=''&&value.value!='0'){
                    this.$toast(this.performanceSourceType2[value.value]+":"+this.performanceSourceType3[value.value])
                }
            },
            selectWorkExperiencesType(value) {
                this.workExperiencesTxt = value.text;
                this.addPara.workExperiences = value.value;
                this.showWorkExperiencesType = false;
                if(value.value!=''&&value.value!='0'){
                    this.$toast(this.workExperiencesType2[value.value]+":"+this.workExperiencesType3[value.value])
                }
            },
            selectActualOperatorType(value) {
                this.actualOperatorTxt = value.text;
                this.addPara.actualOperator = value.value;
                this.showActualOperatorType = false;
                if(value.value!=''){
                    this.$toast(this.actualOperatorType2[value.value]+":"+this.actualOperatorType3[value.value])
                }
            },
            selectFromOtherBanksType(value) {
                this.fromOtherBanksTxt = value.text;
                this.addPara.fromOtherBanks = value.value;
                this.showFromOtherBanksType = false;
                if(value.value==''||value.value=='0'){
                    this.addPara.operator='';
                }
                if(value.value!=''){
                    this.$toast(this.fromOtherBanksType2[value.value]+":"+this.fromOtherBanksType3[value.value])
                }
            },
            selectOperatorType(value) {
                this.operatorTxt = value.text;
                this.addPara.operator = value.value;
                this.showOperatorType = false;
            },
            selectRiskLevelType(value) {
                this.riskLevelTxt = value.text;
                this.addPara.riskLevel = value.value;
                this.showriskLevelType = false;
                if(value.value!=''){
                    this.$toast(this.riskLevelType2[value.value]+":"+this.riskLevelType3[value.value])
                }
            },
            back() {
                this.$router.back()
            },
            updateRiskInfo() {
                if (this.addPara.actualOperator == '1') {
                    this.addPara.actualOperatorRelation = '';
                    this.addPara.actualOperatorName = '';
                    this.addPara.actualOperatorPhone = '';
                }
                if (this.addPara.fromOtherBanks == '0') {
                    this.addPara.operator = '';
                    this.addPara.operatorRelation = '';
                    this.addPara.operatorName = '';
                    this.addPara.operatorPhone = '';
                    this.addPara.industry = '';
                    this.addPara.businessDescription = '';
                    this.addPara.serviceYears = '';
                    this.addPara.reasonForDoing = '';
                }
                if (this.addPara.operator == '1') {
                    this.addPara.operatorRelation = '';
                    this.addPara.operatorName = '';
                    this.addPara.operatorPhone = '';
                }
                if (this.addPara.operator == '2') {
                    this.addPara.serviceYears = '';
                    this.addPara.reasonForDoing = '';
                }
                if (this.addPara.constructionType != '0') {
                    this.addPara.constructionTypeDesc = '';
                }
                if (this.addPara.businessPlaceType== '2') {
                    this.addPara.actualBusinessPlace = '';
                }
                if((this.addPara.riskLevel!='')&&(this.addPara.notes=='')){
                    this.$message.warning('请在备注栏输入自评考虑原因说明!');
                    return;
                }
                updStationRiskTag({
                    stationNo: this.stationNo,
                    stationRiskTag: this.addPara
                }).then(res => {
                    console.log("res", res)
                    if (res.businessCode=== 200) {
                        this.$toast('提交成功')
                        this.$router.back()
                    }
                })
            }

        }
    }
</script>

<style lang="less" scoped>
    .newmanager-info {
        min-height: 100%;
        background: #F7F8F9;
        position: relative;

        .itembg-img {
            width: 100%;
        }

        .img-wrap {
            position: relative;

            div {
                color: #fff;
                font-size: 0.48rem;
                position: absolute;
                top: 1.5rem;
                left: 0.3rem;
            }
        }

        .content {
            background: #F7F8F9;
            width: 100%;
            border-top-left-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
            margin-top: -1.3rem;
            position: relative;
            padding-bottom: 2rem;

            .title {
                text-align: left;
                background: #ffffff;
                height: 0.8rem;
                line-height: 0.8rem;
                padding: 0 0.3rem;
                color: #333333;
                font-weight: bold;
                font-size: 15px;
                margin-top: 0.2rem;

            }

            div.title:before {
                width: 8px;
                height: 5px;
                color: #3f7c53;
                content: "| ";
            }
        }

        .flex {
            display: flex;
        }

        .mt2 {
            margin-top: 0.2rem;
        }

        .img-icon {
            width: 0.4rem;
        }

        .title {
            text-align: left;
            background: #ffffff;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.3rem;
            color: #333333;
            font-weight: bold;
            font-size: 15px;
            margin-top: 0.2rem;
        }

        .pl15 {
            padding: 0.1rem 0.3rem;
            background: #fff;
        }

        .van-checkbox {
            margin: 0.3rem 0;
        }

        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 11;

            .left-btn {
                background: #fff;
                color: #333;
                border: 1px solid #E5E5E5;
                margin-right: 0.24rem;
            }

            .hodeDisabled {
                color: #E5E5E5;
                background: #fff;
            }
        }
    }
</style>
<style>
    .siteInfo .van-tab__text {
        font-size: 15px;
    }

    .siteInfo .van-cell {
        min-height: 48px;
    }

    .siteInfo .van-cell__title {
        font-size: 15px;
        color: #333333;
    }

    .siteInfo .van-field__control {
        font-size: 15px;
    }

    .siteInfo .van-radio {
        font-size: 15px;
    }

    .siteInfo .van-field__clear, .van-field__right-icon {
        line-height: 0.35rem;
    }

    .itembg-img {
        width: 100%;
    }

    .newsite-wrap {
        min-height: 100%;
        background: #F7F8F9;
        position: relative;

    .itembg-img {
        width: 100%;
    }

    .img-wrap {
        position: relative;

    div {
        color: #fff;
        font-size: 0.48rem;
        position: absolute;
        top: 1.5rem;
        left: 0.3rem;
    }

    }
    .nextBtn {
        width: 100%;
        padding: 12px;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 11;

    .left-btn {
        background: #fff;
        color: #333;
        border: 1px solid #E5E5E5;
        margin-right: 0.24rem;
    }

    .hodeDisabled {
        color: #E5E5E5;
        background: #fff;
    }

    }
    .content {
        background: #F7F8F9;
        width: 100%;
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
        margin-top: -1.3rem;
        position: relative;
        padding-bottom: 0.5rem;

    .title {
        text-align: left;
        background: #ffffff;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0 0.3rem;
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-top: 0.2rem;

    &
    :nth-child(1) {
        margin-top: 0;
    }

    }
    div.title:before {
        width: 8px;
        height: 5px;
        color: #3f7c53;
        content: "| ";
    }

    }
    .flex {
        display: flex;
    }

    .img-icon {
        width: 0.4rem;
    }

    .newsite-flex {
        padding-bottom: 0.2rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        color: #3f7c53;
    }

    .mt2 {
        margin-top: 0.2rem;
    }

    .title {
        text-align: left;
        background: #ffffff;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0 0.3rem;
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-top: 0.2rem;
    }

    div.title:before {
        width: 8px;
        height: 5px;
        color: #3f7c53;
        content: "| ";
    }

    .tab-content {
        padding-bottom: 1rem;
    }

    .radiogroup {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .checkboxitem {
        background: #fff;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0.2rem 0.32rem;
    }

    }

</style>
<style lang="less">
    @import "../../style/putInto.less";

</style>
